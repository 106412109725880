@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: "CircularStd-Book", sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
}

body::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
    background-color: #57b1f0;
    border-radius: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

@media (min-width: 1024px) {
    .about-bg {
        background-image: url("https://res.cloudinary.com/react-app-images/image/upload/v1652919111/sabi-images/about-header-bg_obv2hj.png");
    }

    .sabi-bookkeeping-bg {
        background-image: url("https://res.cloudinary.com/react-app-images/image/upload/v1652914113/sabi-images/sabi-blue-gradient_a4ycyu.png");
    }
    .swift-receipt-bg {
        background-image: url("https://res.cloudinary.com/react-app-images/image/upload/v1652916951/sabi-images/red-gradient_jr0gx6.png");
    }
}

@media (max-width: 1023px) {
    .about-bg {
        background-image: url("https://res.cloudinary.com/react-app-images/image/upload/v1652916401/sabi-images/about-blue-gradient_zsrn5b.png");
    }

    .sabi-bookkeeping-bg {
        background-image: url("https://res.cloudinary.com/react-app-images/image/upload/v1652914113/sabi-images/sabi-blue-gradient-mobile_t2bpzn.png");
    }

    .swift-receipt-bg {
        background-image: url("https://res.cloudinary.com/react-app-images/image/upload/v1652916951/sabi-images/red-gradient-mobile_qgkgpx.png");
    }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.Toastify__toast--success {
    border: 1px solid #6ee7b7 !important;
    text-color: #fff !important;
    border-radius: 6px !important;
    background: #6ee7b7 !important;
    font-family: "CircularStd-Book", sans-serif !important;
    font-size: 14px !important;
    box-shadow: -5px 10px 20px -8px rgba(20, 17, 17, 0.49);
    -webkit-box-shadow: -5px 10px 20px -8px rgba(20, 17, 17, 0.49);
    -moz-box-shadow: -5px 10px 20px -8px rgba(20, 17, 17, 0.49) !important;
}

.Toastify__toast--error {
    border: 1px solid #d33730;
    text-color: #fff !important;
    border-radius: 6px !important;
    background: #d33730 !important;
    font-size: 14px !important;
    font-family: "CircularStd-Book", sans-serif !important;
    box-shadow: -5px 10px 20px -8px rgba(20, 17, 17, 0.49);
    -webkit-box-shadow: -5px 10px 20px -8px rgba(20, 17, 17, 0.49);
    -moz-box-shadow: -5px 10px 20px -8px rgba(20, 17, 17, 0.49) !important;
}

.shadowed {
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(196, 196, 196, 0) 156.08%
    );
    backdrop-filter: blur(13.2163px);
    border-color: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0)
    );
    border-width: 1.19px;
}

.faq-content:last-child {
    border-bottom: none !important;
}

.ml4 .letters {
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    opacity: 0;
}

@layer base {
    @font-face {
        font-family: "CircularStd-Black";
        font-weight: 900;
        src: local("CircularStd-Black"),
            url(./components/assets/fonts/CircularStd-Black.otf)
                format("opentype");
    }
    @font-face {
        font-family: "CircularStd-Bold";
        font-weight: 700;
        src: local("CircularStd-Bold"),
            url(./components/assets/fonts/CircularStd-Bold.otf)
                format("opentype");
    }
    @font-face {
        font-family: "CircularStd-Medium";
        font-weight: 500;
        src: local("CircularStd-Medium"),
            url(./components/assets/fonts/CircularStd-Medium.otf)
                format("opentype");
    }

    @font-face {
        font-family: "CircularStd-Book";
        font-weight: 300;
        src: local("CircularStd-Book"),
            url(./components/assets/fonts/CircularStd-Book.otf)
                format("opentype");
    }

    /* 
  Font Weight	Numerical Weight
  "Thin", "Hairline"	100
  "Extra Light", "Ultra Light"	200
  "Light", "Book"	300
  "Normal", "Regular", "Roman", "Standard", "Plain"	400/Normal
  "Medium", "Demi"	500
  "Semi Bold", "Semi Bld", "Demi Bold", "Demi Bld"	600
  "Bold", "Bld"	700/Bold
  "Extra Bold", "Ultra Bold", "Extra Bld", "Ultra Bld"	800
  "Black", "Heavy", "Ultra", "Fat", "Poster", "Ultra Black" 	 900 
  */
}
